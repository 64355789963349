<template>
  <van-nav-bar left-arrow fixed placeholder title="收货单详情" @click-left="onClickLeft" @click-right="onClickRight">
    <template #right v-if="state.detail.signStatus != 2"><van-button v-if="!state.err" type="default" size="small">下载</van-button></template>
  </van-nav-bar>

  <template v-if="!state.err">
    <van-skeleton title avatar :row="2" style="background: #fff;padding:10px;" :loading="loading">
      <step-tig :step="state.detail"/>
    </van-skeleton>

    <van-skeleton title :row="4" style="background: #fff;margin-top:10px;padding:10px;" :loading="loading">
      <home-item :item="state.detail" type="detail"/>
    </van-skeleton>

    <van-skeleton title :row="2" style="background: #fff;margin-top:10px;padding:10px;" :loading="loading">
      <cell-tag :title="`收货单 (共${state.detail.infoList.length}张)`">
        <div v-for="(item,idx) in state.detail.infoList" :key="idx" >
          <div><span :class="{'red':item.cancelFlag}">{{item.cancelFlag ? '作废单据' : '收货单' + (idx + 1) }}</span>-{{item.orderCode}} <copy-com  :oId="item.orderCode"/></div>
          <div class="file-url">
            <van-image
            width="100%"
            height="100%"
            lazy-load
            :src="item.fileUrl"
            @click="imgPreview(idx)"
          />
          </div>
          
        </div>
      </cell-tag>
    </van-skeleton>
    <van-skeleton title :row="2" style="background: #fff;margin-top:10px;padding:10px;" :loading="loading">
      <cell-tag title="下载记录">
        <div v-for="(o,index) in state.detail.downloadLog" :key="index">{{o.createTime}} {{o.personName}}</div>
        <div v-if="state.detail.downloadLog.length == 0">未有下载记录</div>
      </cell-tag>


      <div class="foot-h"></div>
      <div class="flex det-btn" v-if="state.detail.signStatus == 1 || (state.detail.signStatus == 0 && state.detail.signPersonFlag)">
        <van-button size="small" style="width:16%" @click="clickRefuse">拒签</van-button>
        <van-button size="small" style="width:80%" @click="clickSign" type="primary">核查无误，进行签收</van-button>
      </div>

      <div v-else class="flex det-btn det-btn-text" :class="{ 'no-tag': state.detail.signStatus == 2,'ok-tag': state.detail.signStatus == 3}">
      {{ state.detail.signStatus == 0 ? '签收中' : state.detail.signStatus == 2 ? '已拒签' : '已签收'}}
      {{ state.detail.signPersonName ?( "(" + state.detail.signPersonName + ")") : ''}}
      </div>
    </van-skeleton>
  </template>
  <template v-if="!loading && state.err">
    <empty :title="state.err" />
  </template>

  <van-popup v-model:show="show" style="width: 80%" closeable round lock-scroll lazy-render>
    <div class="popup-det">
      <h4>拒签</h4>
      <div>请选择拒签理由</div>
    </div>
    <div class="popup-cell">
      <van-radio-group v-model="checked">
        <van-cell-group>
          <van-cell :border="false" title="商品型号或数量不符" @click="checked = '商品型号或数量不符'">
            <template #right-icon>
              <van-radio name="商品型号或数量不符" icon-size="16px" checked-color="#ff7300"/>
            </template>
          </van-cell>
          <van-cell :border="false" title="商品损坏" @click="checked = '商品损坏'">
            <template #right-icon>
              <van-radio name="商品损坏" icon-size="16px" checked-color="#ff7300"/>
            </template>
          </van-cell>
          <van-cell :border="false" title="商品金额不符" @click="checked = '商品金额不符'">
            <template #right-icon>
              <van-radio name="商品金额不符" icon-size="16px" checked-color="#ff7300"/>
            </template>
          </van-cell>
          <van-cell :border="false" title="其他原因" @click="checked = '其他原因'">
            <template #right-icon>
              <van-radio name="其他原因" icon-size="16px" checked-color="#ff7300"/>
            </template>
          </van-cell>
        </van-cell-group>
      </van-radio-group>
    </div>
    <div class="popup-btn">
      <van-button size="small" round style="width:100%;" @click="clickRefuseOk">确认拒签</van-button>
    </div>
  </van-popup>
</template>
<script>
import { reactive, onMounted,ref } from "vue";
import { Image as VanImage, ImagePreview, Popup, RadioGroup, Radio } from 'vant';
import { deliveryNoticesId, sign, refuse, download } from "@/api/home";
import { Dialog, Toast } from "vant";
import homeItem from "@/components/home-item";
import { useRouter, useRoute } from "vue-router";
import StepTig from '@/components/step-tig.vue';
import cellTag from '@/components/cell-tag.vue'
import copyCom from "@/components/copy-com.vue"
import empty from "@/components/empty";
export default {
  components: {
    homeItem,
    StepTig,
    empty,
    cellTag,
    copyCom,
    VanImage,
    [ImagePreview.Component.name]: ImagePreview.Component,
    [Popup.name]: Popup,
    [RadioGroup.name]: RadioGroup,
    [Radio.name]:Radio
  },
  setup() {
    const routers = useRouter();
    const route = useRoute()
    
    const state = reactive({
      err: '',
      detail: {
        id: 1,
        merchantName: '',
        customerName: '',
        address: '',
        amount: '',
        signStatus: '1',
        signTime: '',
        refuseReason: '',
        signPersonName: '',
        infoList: [
            {
              orderCode: "",
              orderTime: "",
              cancelFlag: true,
              fileUrl: ''
            }
          ],
        downloadLog: [{
          createTime: '',
          personName: ''
        }]
      }
    });

    // 返回首页
    const onClickLeft = () => {
      routers.go(-1)
      // routers.push({ path: '/home' })
    };

    // 放大图片
    const imgPreview = (idx) => {
      ImagePreview({
        images: state.detail && state.detail.infoList.map( v => {
          return v.fileUrl
      }),
        startPosition: idx
      })
    };
    const loading = ref(true);
    // 下载
    const onClickRight = () => {
      if(loading.value || state.err) return
      const isd = state.detail.infoList.map( v => {
        return v.orderCode
      })
      Dialog.confirm({
        title: "下载",
        confirmButtonText: "下载",
        confirmButtonColor: "#FC6621",
        allowHtml: true,
        message: `将下载${state.detail.merchantName}提供的收货单\n${isd}`,
      }).then(() => {
        getDownload()
      });
    };

    const getDownload = async () => {
      const { id } = route.params
      const { recode, msg, url } = await download(id);
      if(recode == 200) {
        window.location.href = url;
        // routers.push({ path: url })
      } else {
        Toast(msg)
      }
    }

    onMounted(() => {
      delivery();
    });

    // 获取详情信息
    const delivery= async () => {
      const { id } = route.params
      const { data, recode, msg } = await deliveryNoticesId(id);
      if(recode == 200) {
        state.detail = data

        state.err = ''
      } else {
        state.err = msg
      }
      loading.value = false;
    };

    // 拒签
    const show = ref(false);
    const clickRefuse = () => {
      show.value = true;
    }
    const checked = ref('商品型号或数量不符');
    const clickRefuseOk = async () => {
      show.value = false;
      const { id } = route.params
      const rron = { "refuseReason": checked.value }
      const { recode } = await refuse(id, rron);
      if (recode == 200) {
        delivery();
      }
    }

    // 签收
    const clickSign = () => {
      Dialog.confirm({
        title: '提示',
        confirmButtonText: '签收',
        confirmButtonColor: "#FC6621",
        message: `商品无误后请进入签收`,
      }).then(() => {
        postSign()
      });
    }

    const postSign = async () => {
      const { id } = route.params
      const { recode , url } = await sign(id);
      if(url == '') return Toast('链接不能为空')
      if (recode == 200 && url) {
        location.href = url
      }
    }

    return {
      loading,
      checked,
      clickRefuseOk,
      clickSign,
      show,
      state,
      imgPreview,
      onClickLeft,
      onClickRight,
      clickRefuse
    };
  }
};
</script>

<style lang="less">
.file-url {
  display: flex;
  align-items: center;
  width: 100%;
  min-height: 80px;
  margin-bottom: 10px;
  img {
    width: 100%;
  }
}
.foot-h {
  height: 60px;
}
.det-btn {
  position:fixed;
  bottom: 0;
  left: 0;
  right: 0;
  height: 50px;
  background-color: #fff;
  margin: 0;
  padding-left: 10px;
  padding-right: 10px;
  border-top: 1px solid #e5e5e5;
  padding-bottom: constant(safe-area-inset-bottom);
  padding-bottom: env(safe-area-inset-bottom);
  transform: translateZ(0);
  -webkit-transform: translateZ(0);
}
.det-btn-text{
  font-size: 13px;
  justify-content: center;
}
.no-tag {
  background:#d9001b;
  color:#fff;
}
.ok-tag {
  background:#70b603;
  color:#fff;
}
.popup-det {
  font-size: 13px;
  padding: 6px 16px 10px 16px;
  color: #999;
  h4 {
    text-align: center;
    color: #333;
    font-size: 16px;
    font-weight: initial;
  }
}
.popup-cell {
  font-size: 12px;
  .van-radio-group {
    .van-cell {
      font-size: 13px;
      padding: 3px 16px;
    }
  }
}
.popup-btn {
  padding: 16px 16px 20px 16px;
}
</style>
