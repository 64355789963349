<template>
  <div class="flex step-tig">
    <van-icon :name="st == 2 ? 'close' : st == 3 ? 'passed' : 'warning-o'" size="26" />
    <div>
      <h4>收货单状态：{{statusText}}</h4>
      <p class="grey" v-if="st == 0 || st == 1">请现场核实货物数量型号无误后再<span class="c-der">确认签收</span>，若有不符请拒签</p>
      <p class="grey" v-if="st == 2">本单据已于{{step.signTime}} 拒签<br />拒签理由：{{step.refuseReason}}</p>
      <p class="grey" v-if="st == 3">本单据已于{{step.signTime}} 签收</p>
    </div>
  </div>
</template>
<script>
import { computed } from "vue";
export default {
  props: {
    step: {
      type: Object,
    }
  },
  setup(props) {
    const st = computed(() => {
      return props.step.signStatus
    });
    // 签收中：0，待签收：1，已拒签：2，已签收：3
    const statusText = computed(() => {
      return props.step.signStatus == 0
        ? "签收中"
        : props.step.signStatus == 1
        ? "待签收"
        : props.step.signStatus == 2
        ? "已拒签"
        : "已签收"
    });

    return {
      statusText,
      st
    };
  },
};
</script>

<style lang="less" scoped>
.step-tig {
  background-color: #fff;
  align-items: flex-start;
  padding: 10px;
  font-size: 13px;
  justify-content: flex-start;
  line-height: 1.6;
  h4 {
    font-size: 15px;
    margin: 3px 10px;
    color: #333;
  }
  p {
    margin: 0 0 0 10px;
  }
}
</style>