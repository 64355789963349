<template>
  <header class="cell-tag">{{ title }}</header>
  <div class="cell-slot"><slot></slot></div>
</template>
<script>
export default {
  props: {
    title: {
      type: String,
    },
  }
};
</script>

<style lang="less" scoped>
.cell-tag {
  margin-top: 10px;
  background-color: #fff;
  padding: 0 10px;
  font-size: 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 40px;
  border-bottom: 1px solid #e5e5e5;
}
.cell-slot {
  background-color: #fff;
  margin: 0;
  padding: 10px;
  line-height: 1.9;
  font-size: 12px;
  color: #666;
}
</style>